import React from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import Select from 'react-select';
import Email from './Email.js'

class Results extends React.Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props)
        const { cookies } = props;

        this.backendUrl = props.backendUrl;
        // Control references
        this.firstLiRef = React.createRef();
        this.secondLiRef = React.createRef();
        this.thirdLiRef = React.createRef();

        this.backLiClassName = "page-item disabled";
        this.firstLiClassName = "page-item active";
        this.secondLiClassName = "page-item";
        this.thirdLiClassName = "page-item";
        this.nextLiClassName = "page-item";

        this._resultsPerPage = cookies.get("resultsPerPage") || '5';
        this.showPageButtons = true;

        this.state = {

            transactions: [],
            filteredTransactions: [],
            rowsPerPage: { value: parseInt(this._resultsPerPage), label: this._resultsPerPage },
            currentPage: 1

        };

        // Set hover/selected text color of react-select controls to be white
        this.customStyles = {
            option: (base, state) => ({
                ...base,
                "&:hover": {
                    color: "white"
                },
                color: state.isFocused || state.isSelected ? 'white' : 'black',
                height: 45,
                minHeight: 45,
                paddingTop: 15,
                paddingBottom: 0
            }),
            dropdownIndicator: base => ({
                ...base,
                width: 35, // make dropdown arrow smaller
                paddingTop: 10,
                paddingBottom: 0,
                height: 45,
                minHeight: 45

            }),
            container: base => ({
                ...base,
                width: 75, // fix size of the control
                minWidth: 75

            }),
            menu: (base) => ({
                ...base,
                width: "max-content",
                minWidth: "100%"
            }),
            control: (base, state) => ({
                ...base,
                height: 45,
                minHeight: 45,
            }),
            input: (base, styles) => ({
                ...base,
                paddingTop: 0,
                paddingBottom: 1
            })
        };

        this.rowsPerPageOptions = [
            { value: 5, label: '5' },
            { value: 10, label: '10' },
            { value: 20, label: '20' },
            { value: 50, label: '50' },
            { value: 100, label: '100' },
            { value: 250, label: '250' },
            { value: 500, label: '500' }

        ];

        this.perRecordSelectChange = this.perRecordSelectChange.bind(this);
        this.backLiClick = this.backLiClick.bind(this);
        this.nextLiClick = this.nextLiClick.bind(this);
        this.liClick = this.liClick.bind(this);
        this.downloadOnClick = this.downloadOnClick.bind(this);
        this.backButtonClick = this.backButtonClick.bind(this);
    }
    backButtonClick() {
        this.props.backButton();
    }
    async downloadOnClick(event) {

        const hosttransactionId = event.currentTarget.id;

        var button = document.getElementById(hosttransactionId);
        var index = event.currentTarget.parentElement.parentElement.id;
        var date = new Date(this.state.filteredTransactions[index].date + "Z").toLocaleString()

        button.innerHTML = "<span class='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>";    
        button.disabled = true;

        var fileName = date.replace(', ', '_');
        fileName = "GRIDSERVE_Receipt_" + fileName.replaceAll('/', '-').replaceAll(':', '').slice(0, -2);

        await fetch((this.backendUrl ? this.backendUrl : "") + "/CrmService/GeneratePDF?hostTransactionId=" + hosttransactionId, {
            method: "GET",
            headers: new Headers({
                RPApiKey: process.env.REACT_APP_API_KEY,
                pragma: 'no-cache',
                'cache-control': 'no-cache'
                })
            })
            .then((res) => { return res.blob(); })
            .then((data) => {
                var a = document.createElement("a");
                a.href = window.URL.createObjectURL(data);
                a.download = fileName;
                a.click();
                a.remove();
            })
            .finally(() => {
                button.innerHTML = "<span class='spinner-border spinner-border-sm' role='status' hidden aria-hidden='true'></span><b>&nbsp;Download</b>";
                 })


        button.disabled = false;
    }

    backLiClick() {

        if (this.state.currentPage % 3 === 1) {

            this.firstLiRef.current.innerHTML = (parseInt(this.firstLiRef.current.innerHTML) - 3).toString();
            this.secondLiRef.current.innerHTML = (parseInt(this.secondLiRef.current.innerHTML) - 3).toString();
            this.thirdLiRef.current.innerHTML = (parseInt(this.thirdLiRef.current.innerHTML) - 3).toString();

        }

        var filtered = this.filterTransactions(this.state.rowsPerPage.value, null, this.state.currentPage + -1);
        this.setState({
            currentPage: this.state.currentPage - 1,
            filteredTransactions: filtered
        });
    }
    liClick(event) {
        var filtered = this.filterTransactions(this.state.rowsPerPage.value, null, parseInt(event.target.innerHTML));
        this.setState({
            currentPage: parseInt(event.target.innerHTML),
            filteredTransactions: filtered
        });
    }
    nextLiClick() {

        if (this.state.currentPage % 3 === 0) {

            this.firstLiRef.current.innerHTML = (parseInt(this.firstLiRef.current.innerHTML) + 3).toString();
            this.secondLiRef.current.innerHTML = (parseInt(this.secondLiRef.current.innerHTML) + 3).toString();
            this.thirdLiRef.current.innerHTML = (parseInt(this.thirdLiRef.current.innerHTML) + 3).toString();        

        }
        var filtered = this.filterTransactions(this.state.rowsPerPage.value, null, this.state.currentPage + 1);
        this.setState({
            currentPage: this.state.currentPage + 1,
            filteredTransactions: filtered
        });
    }

    perRecordSelectChange(event) {

        const { cookies } = this.props;
        cookies.set("resultsPerPage", event.value, {
            path: "/",
            expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
        });

        const filtered = this.filterTransactions(event.value, null, 1);

        if (this.firstLiRef.current !== null) {
            this.firstLiRef.current.innerHTML = 1;
            this.secondLiRef.current.innerHTML = 2;
            this.thirdLiRef.current.innerHTML = 3;
        }

        this.setState({
            rowsPerPage: event,
            currentPage: 1,
            filteredTransactions: filtered
        })
    }

    filterTransactions(rowsPerPage, trans = null, _currentPage = null) {

        var _transactions = [];
        var currentPage = 0;

        if (_currentPage == null)
            currentPage = this.state.currentPage;
        else
            currentPage = _currentPage;

        if (trans === null) {
            _transactions = this.state.transactions;
        }
        else {
            _transactions = trans;
        }

        const pages = Math.ceil(_transactions.length / rowsPerPage);

        if (pages === 1)
            this.showPageButtons = false;
        else
            this.showPageButtons = true;

        var lastPage = false;
        var firstPage = false;
        var remainingPages = pages - currentPage;

        if (currentPage * rowsPerPage >= _transactions.length + 1)
            lastPage = true;

        if (currentPage === 1)
            firstPage = true;

        var filteredTransactions = [];

        if (lastPage) {
               filteredTransactions = _transactions.slice((currentPage * rowsPerPage - rowsPerPage), _transactions.lenght);
        }
        else if (firstPage) {
            filteredTransactions = _transactions.slice((currentPage * rowsPerPage - rowsPerPage), currentPage * rowsPerPage);
        }
        else {
            filteredTransactions = _transactions.slice((currentPage * rowsPerPage - rowsPerPage), currentPage * rowsPerPage);
        }

        var num = 0;

        if (currentPage < 4)
            num = currentPage;
        else
            num = currentPage % 3;

        switch (num) {

            case 1:
                this.backLiClassName = (firstPage) ? "page-item disabled" : "page-item" ;
                this.firstLiClassName = "page-item active";
                this.secondLiClassName = (remainingPages > 0) ? "page-item" : "page-item disabled";
                this.thirdLiClassName = (remainingPages > 1) ? "page-item" : "page-item disabled";
                this.nextLiClassName = (remainingPages > 0) ? "page-item" : "page-item disabled";
                break;
            case 2:
                this.backLiClassName = "page-item";
                this.firstLiClassName = "page-item";
                this.secondLiClassName = "page-item active";
                this.thirdLiClassName = (remainingPages > 0) ? "page-item" : "page-item disabled";
                this.nextLiClassName = (remainingPages > 0) ? "page-item" : "page-item disabled";
                break;
            case 3:
            case 0:
                this.backLiClassName = "page-item";
                this.firstLiClassName = "page-item";
                this.secondLiClassName = "page-item";
                this.thirdLiClassName = "page-item active";
                this.nextLiClassName = (remainingPages > 0) ? "page-item" : "page-item disabled";
                break;
            default:
                if (lastPage) {
                    this.nextLiClassName = "page-item disabled";
                }
                else {
                    this.nextLiClassName = "page-item";
                }
                break;
        }

        return filteredTransactions;

    }

    async componentDidMount() {

        var cardRef = this.props.CardUniqueRef;

        const response = await fetch((this.backendUrl ? this.backendUrl : "") + "/CrmService/Transactions?uniqueCardRef=" + cardRef, {
            method: "GET",
            headers: new Headers({
                RPApiKey: process.env.REACT_APP_API_KEY,
                pragma: 'no-cache',
                'cache-control': 'no-cache'
            })
        });

        const trans = await response.json();

        const filtered = this.filterTransactions(this._resultsPerPage, trans)

        this.setState({
            transactions: trans,
            filteredTransactions: filtered
        });

    }

    render() {

        const transactions = this.state.transactions;
        const filteredTransactions = this.state.filteredTransactions;

        var oneRecord = false;

        if (transactions.length === 1)
            oneRecord = true;

        if(transactions.length === 0)
            return null;

        const last4d = transactions[0].maskedCardNumber.substr(transactions[0].maskedCardNumber.length - 4);

        return (
            <>
                <div className="card-results">

                    <h3 className="justify-content-center" data-testid="resultsHeader">Charge Sessions for card ending in  <b data-testid="last4"> {last4d}</b></h3>
                <p data-testid="recordsFound"> {this.state.transactions.length} {!oneRecord ? "records" : "record"} found.</p>

                <hr />

                <div className="row row-cols-2 justify-content-start pb-3 pe-2">
                        <div className="col-4 d-flex justify-content-start">
                            <button data-testid="backBtn" className="btn btn-outline-secondary mt-auto" onClick={this.backButtonClick}> Back </button>
                    </div>
                    <div className="col-8 d-flex justify-content-end">
                        <label htmlFor="rowsPerPage" className='pt-2 ps-2 pe-2'>View per page:</label>
                        <Select id='rowsPerPage' ref={this.perRecordSelect}
                            options={this.rowsPerPageOptions}
                            defaultValue={this.state.rowsPerPage}
                            onChange={this.perRecordSelectChange}
                            isSearchable={false}
                            styles={this.customStyles}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    primary50: '#7ac142',
                                    primary25: '#7ac142',
                                    primary: '#7ac142'
                                },
                            })} />
                    </div>

                </div>
                    <Table className="table table-borderless table-striped">
                        <Thead >
                        <Tr>
                            <Th>Location</Th>
                            <Th>Date</Th>
                            <Th>Amount</Th>
                            <Th>Status</Th>
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {filteredTransactions.map((tr, i) => {
                            return (
                                <Tr key={i} id={i}>
                                    <Td>{tr.location.label}</Td>
                                    <Td>{new Date(tr.date + "Z").toLocaleString()} </Td>
                                    <Td>£{tr.amount.toFixed(2)}</Td>
                                    <Td>{tr.emailStatus === 717600001
                                        ? <img alt="Email not sent" src={this.backendUrl+"/mailunsent.svg"}/>
                                        : <img alt="Email sent" src={this.backendUrl+"/mailsent.svg"} />
                                    }</Td>
                                    <Td key={tr.hostTransactionId}>
                                        <button data-testid="downloadBtn" onClick={this.downloadOnClick} id={tr.hostTransactionId} className="btn btn-primary w-100 btn-sm">
                                            <span className='spinner-border spinner-border-sm' role='status' hidden aria-hidden='true'></span><b>&nbsp;Download</b>
                                        </button>
                                    </Td>
                                </Tr>
                            );
                        })}
                    </Tbody>
                    </Table>
                    {this.showPageButtons
                    ? <div className="d-flex justify-content-center">
                        <nav aria-label="Page navigation" >
                        <ul className="pagination">
                                <li className={this.backLiClassName}> <button className="page-link" id="backLi" onClick={this.backLiClick} aria-label="Previous">Previous</button></li>
                                <li className={this.firstLiClassName}><button className="page-link" ref={this.firstLiRef} onClick={this.liClick} id="firstLi">1</button></li>
                                <li className={this.secondLiClassName}><button className="page-link" ref={this.secondLiRef} onClick={this.liClick} id="secondLi">2</button></li>
                                <li className={this.thirdLiClassName}><button className="page-link" ref={this.thirdLiRef} onClick={this.liClick} id="thirdLi">3</button></li>
                                <li className={this.nextLiClassName}><button className="page-link" onClick={this.nextLiClick} id="nextLi" aria-label="Next">Next</button>
                            </li>
                        </ul>
                    </nav>
                    </div>
                    : ''
                    }
                    <hr />
                    <Email uniqueCardRef={this.props.CardUniqueRef} backendUrl={this.backendUrl} forgetMeButton={this.backButtonClick} last4digits={transactions[0].maskedCardNumber.substr(transactions[0].maskedCardNumber.length - 4)} />
                </div>
            </>
        );

    }

}

export default withCookies(Results);
