import React from 'react';

const Modal = ({ children, show,deviceType, closeModal }) => (
    <div
        className={`modal ${show ? ' modal-show' : ''}`}
        tabIndex="-1"
        role="dialog"
    >
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Do you trust this {deviceType}?</h4>
                </div>
                <div className="modal-body">
                    {children}
                </div>
                <div className="modal-footer d-flex justify-content-center">
                        <button type="button" className="btn btn-primary" data-testid="modalPrimaryBtn" onClick={() => closeModal("yes")}>
                            Yes
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            onClick={() => closeModal("no")}
                        >
                            No
                        </button>
                </div>
            </div>
        </div>
    </div>
);
export default Modal;