import React from 'react';

const NoResultsModal = ({ children, show, closeModal, textDataId, btnDataId }) => (
    <div
        className={`modal ${show ? ' modal-show' : ''}`}
        tabIndex="-1"
        role="dialog"
    >
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-body" data-testid={textDataId}>
                    {children}
                </div>
                <div className="modal-footer">
                    <button data-testid={btnDataId} type="button" className="btn btn-primary" onClick={() => closeModal()}>
                        <b>OK</b>
                    </button>
                </div>
            </div>
        </div>
    </div>
);
export default NoResultsModal;