import React from 'react';
import config from './../config.json';
import Select from 'react-select';
import Modal from './Modal';
import NoResultsModal from './NoResultsModal';
import Results from './Results';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

class Main extends React.Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props)

        const { cookies } = props;

        // References to input controls
        this.last4Input = React.createRef();
        this.locationInput = React.createRef();
        this.dateInput = React.createRef();
        this.amountInput = React.createRef();
        this.nextButton = React.createRef();
        this.startOverButton = React.createRef();
        this.cardSelect = React.createRef();

        // Input values
        this.last4InputValue = '';
        this.locationInputValue = '';
        this.dateInputValue = '';
        this.amountInputValue = '';

        // Initial params from config (for display order and initial show)
        this.showLast4 = (config.FieldsDisplay[0].order < 20);
        this.showLocation = (config.FieldsDisplay[1].order < 20);
        this.showAmount = (config.FieldsDisplay[2].order < 20);
        this.showDate = (config.FieldsDisplay[3].order < 20);
        this.currentPhase = 10;
        this.showForm = false;
        this.showResults = false;
        this.showCardSelect = false;
        this.showBackButton = false;

        // Other
        this.deviceType = '';
        this.uniqueCardRef = '';
        this.noResultsMsg = '';
        this.backendUrl = '';

        // Cookie management
        var _modalShow = false;

        var _consent = cookies.get("cookieConsent") || 'noCookie';      

        if (_consent === 'noCookie')
            _modalShow = true;
        else
            _modalShow = false;

        // Device detection
        
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) //eslint-disable-line
            || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) { //eslint-disable-line
            this.deviceType = 'mobile device';
        }
        else
            this.deviceType = 'computer';

        // States
        this.state = {

            arrayFields: config.FieldsDisplay,
            fields: [],
            locations: [],
            consent: _consent,
            modalShow: _modalShow,
            noResultsModalShow: false
        };

        // Action bindings
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitCardSelect = this.handleSubmitCardSelect.bind(this);
        this.startOver = this.startOver.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.closeNoResultsModal = this.closeNoResultsModal.bind(this);
        this.locationOnChange = this.locationOnChange.bind(this);
        this.cardSelectOnChange = this.cardSelectOnChange.bind(this);
        this.backButtonFromResults = this.backButtonFromResults.bind(this);

        // Set hover/selected text color of react-select controls to be white
        this.customStyles = {
            option: (base,state) => ({
                ...base,
                "&:hover": {
                    color: "white",

                },
                color: state.isFocused || state.isSelected ? 'white' : 'black',
                height: 45,
                minHeight: 45,
                paddingTop: 15,
                paddingBottom: 0
            }),
            control: (base, state) => ({
                ...base,
                height: 45,
                minHeight: 45,
            }),
            dropdownIndicator: (styles) => ({
                ...styles,
                paddingTop: 10,
                paddingBottom: 0,
                height: 45,
                minHeight: 45
            }),
            input: (base,styles) => ({
                ...base,
                paddingTop: 0,
                paddingBottom: 1
            })
        };
    }

    closeModal(consent) {

        const { cookies } = this.props;
        cookies.set("cookieConsent", consent, {
            path: "/",
            expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
        });
        this.setState({
            modalShow: false,
            consent: consent
        });

    }

    closeNoResultsModal() {

        if (this.nextButton?.current?.disabled != null)
            this.nextButton.current.disabled = false;
        if (this.startOverButton?.current?.disabled != null)
            this.startOverButton.current.disabled = false;

        this.setState({
            noResultsModalShow: false
        })
    }

    renderFields() {

        var last4 = '';
        var location = '';
        var amount = '';
        var date = '';

        if (this.showLast4) {
            last4 = <div className="form-group" key='last4' >
                <label className="form-label"  > Last 4 digits of card or virtual account number: </label>
                <input autoFocus={true} type="text" id="last4digits" name="last4digits" maxLength="4" pattern="^\d{4}$" required ref={this.last4Input} className="form-control" placeholder="XXXX" />
            </div>;
        }

        if (this.showLocation) {
            location = <div className="form-group" key='location' hidden={!this.showLocation}>
                <label className="form-label">Location: </label>
                <Select ref={this.locationInput}
                    options={this.state.locations}
                    onChange={this.locationOnChange}
                    styles={this.customStyles}
                    inputId="react-select-input"
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            primary50: '#7ac142',
                            primary25: '#7ac142',
                            primary: '#7ac142'
                        },
                    })}
                />
            </div>;
        }

        if (this.showAmount) {
            amount = <div className="form-group" key='amount' >
                <label className="form-label">Transaction amount: </label>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text">&#163;</span>
                    </div>
                    <input autoFocus={true} type="number" required min="0.00" ref={this.amountInput} max="10000.00" step="0.01" className="form-control" aria-label="Amount (to the nearest pound)" placeholder="" />

                </div>
            </div>;
        }

        if (this.showDate) {
            date = <div className="form-group" key='date' >
                <label className="form-label">Date: </label>
                <input autoFocus={true} data-testid="dateInput" type="date" min="2020-01-01" max={new Date().toISOString().split("T")[0]}  required ref={this.dateInput} className="form-control" placeholder="Transaction date" />
            </div>;
        }

        const fieldsTmp = [];

        this.state.arrayFields.forEach((data) => {

            switch (data.fieldName) {
                case "last4":
                    fieldsTmp.push(last4);
                    break;
                case "amount":
                    fieldsTmp.push(amount);
                    break;
                case "location":
                    fieldsTmp.push(location);
                    break;
                case "date":
                    fieldsTmp.push(date);
                    break;
                default:
                    break;
            }

        });

        if (this.state.consent === 'noCookie')
            this.setState({
                fields: fieldsTmp,
                modalShow: true
            });
        else
            this.setState({
                fields: fieldsTmp,
                modalShow: false
            });
    }

    async componentDidMount() {
        
        // Receipts Portal parent element is Body when not embedded and Div when embedded
        if (document.getElementById("ReceiptsPortal").parentElement !== document.body) {
            try {
                var scriptUrl = new URL(document.getElementById("ReceiptsPortal").parentElement.querySelector("script[src*='main-receiptsportal']").getAttribute("src"));
                this.backendUrl = scriptUrl.origin;
            }
            catch (err) {
                console.log(err);
                console.log("Cannot find the ReceiptPortal App include - please make sure the script tag is on the page at the same level as the ReceiptPortal div.");
            }
        }

        const response = await fetch(`${this.backendUrl || ""}/CrmService/Locations`, {
            method: "GET",
            headers: new Headers({
                RPApiKey: process.env.REACT_APP_API_KEY,
                pragma: 'no-cache',
                'cache-control': 'no-cache'
            })
        });

        const loc = await response.json();
        this.setState({locations: loc});
        this.state.arrayFields = this.state.arrayFields.sort((a, b) => {
            return a.order - b.order;
        });

        var allCookies = this.props.cookies.getAll();

        const filteredCardCookies = Object.keys(allCookies)
            .filter((key) => key.includes("uniqueCardRef"))
            .reduce((obj, key) => {
                return Object.assign(obj, {
                    [key]: allCookies[key]
                });
            }, {});

        this.cardSelectOptions = [];

        Object.entries(filteredCardCookies).forEach(entry => {

            this.cardSelectOptions.push({ label: "xxxxxxxxxxxx" + entry[0].substr(entry[0].length - 4), value: entry[1] })

        });

        this.cardSelectOptions.push({ label: "None of these", value: "0" });


        if (this.cardSelectOptions.length > 1) {
            this.showForm = false;
            this.showCardSelect = true;
            this.showBackButton = true;
        }

        else {
            this.showForm = true;
            this.showCardSelect = false;
            this.showBackButton = false;
        }
        this.renderFields();

    }

    handleSubmitCardSelect(event) {

        event.preventDefault();

        if (this.cardSelect?.current?.getValue()[0]?.value == null && this.showCardSelect === true) {

            event.currentTarget.elements[0].setCustomValidity('Please select card!');
            event.currentTarget.elements[0].reportValidity();

            return false;
        }
        else {
            this.showCardSelect = false;


            if (this.cardSelect?.current?.getValue()[0]?.value === "0") {
                this.showForm = true;
                this.renderFields();
            }
            else {
                this.uniqueCardRef = this.cardSelect?.current?.getValue()[0]?.value;
                this.showResults = true;
                this.setState({
                    consent: this.state.consent
                });
            }
        }
            
    }

    async startOver() {

        this.currentPhase = 10;
        this.showLast4 = (config.FieldsDisplay[0].order < 20);
        this.showLocation = (config.FieldsDisplay[1].order < 20);
        this.showAmount = (config.FieldsDisplay[2].order < 20);
        this.showDate = (config.FieldsDisplay[3].order < 20);
        this.showResults = false;
        this.last4InputValue = '';
        this.locationInputValue = '';
        this.dateInputValue = '';
        this.amountInputValue = '';
        await this.componentDidMount();
    }

    async backButtonFromResults() {
        await this.startOver();
    }

    locationOnChange(event) {
        document.getElementById('react-select-input')?.setCustomValidity('');

    }
    cardSelectOnChange(event) {
        document.getElementById('react-select-input')?.setCustomValidity('');
    }


    async handleSubmit(event) {

        document.body.style.cursor = 'wait';
        
        event.preventDefault();

        // Custom validity handling of the location field
        if (this.locationInput?.current?.getValue()[0]?.value == null && this.showLocation===true) {

            if (event.currentTarget.elements['react-select-input'] !== undefined) {

                event.currentTarget.elements['react-select-input'].setCustomValidity('Please select location!');
                event.currentTarget.elements['react-select-input'].reportValidity();
            }

            this.renderFields();

            return false;
        }

        // Disable buttons
        this.nextButton.current.disabled = true;
        if (this.startOverButton?.current?.disabled != null)
            this.startOverButton.current.disabled = true;

        // Query CE
        try {

        if (this.last4Input.current != null)
            this.last4InputValue = this.last4Input.current.value;

        if (this.locationInput.current != null)
            this.locationInputValue = this.locationInput.current.getValue()[0].value

        if (this.dateInput.current != null)
            this.dateInputValue = this.dateInput.current.value;

        if (this.amountInput.current !=null)
            this.amountInputValue = this.amountInput.current.value;


            var last4param = '';
            var locationParam = '';
            var dateParam = '';
            var amountParam = '';

            if (this.last4InputValue !== '')
                last4param = 'Last4=' + this.last4InputValue + '&';

            if (this.locationInputValue !== '')
                locationParam = 'Location=' + this.locationInputValue + '&';

            if (this.dateInputValue !== '')
                dateParam = 'Date=' + this.dateInputValue + '&';

            if (this.amountInputValue !== '')
                amountParam = 'Amount=' + this.amountInputValue + '&';

            var fetchUrl = (last4param + locationParam + dateParam + amountParam).slice(0, -1);

            const response = await fetch((this.backendUrl ? this.backendUrl : "") + "/CrmService/FindCard?" + fetchUrl, {
                method: "GET",
                headers: new Headers({
                    RPApiKey: process.env.REACT_APP_API_KEY,
                    pragma: 'no-cache',
                    'cache-control': 'no-cache'
                })
            });
            const msg = await response.json();

            switch (msg.messageTypeValue) {
                case (0):

                    document.body.style.cursor = 'default';
                    this.noResultsMsg = 'No charge session receipts found for this card or virtual account number. Please keep in mind they may take 3 days to appear on the portal. Please check the information provided and reference the FAQ for help.';
                    this.setState({
                        noResultsModalShow: true
                    })

                    return true;

                case (1):

                    this.uniqueCardRef = msg.cardUniqueRef;           

                    this.showForm = false;
                    this.showResults = true;

                    const { cookies } = this.props;

                    if (this.state.consent === "yes") {
                        cookies.set("uniqueCardRef-" + this.last4InputValue, msg.cardUniqueRef, {
                            path: "/",
                            expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
                        });
                    }
                    else {
                        this.setState({
                            consent: "no"
                        })
                    }
                    document.body.style.cursor = 'default';

                    return true;

                case (2): // found more than 1 transaction continue with code below or if last step return modal dialog
                    break;
                case (3): // found more than 1 after all 4 inputs
                    this.noResultsMsg = 'Cannot find an unique payment transaction. Please contact us for support!';
                    this.setState({
                        noResultsModalShow: true
                    });
                    return true;
                default:

                    break;
            }


        }
        catch (e) {
            console.log(e);
        }

        // Show hide fields logic. Not reached if Unique Card Ref is found from the CE query above
        var BreakException = {};

        try {

            for (var i = 0; i < this.state.arrayFields.length; i++) {


                if ((this.state.arrayFields[i].fieldName === "last4" && this.showLast4 === false && this.currentPhase + 10 - this.state.arrayFields[i].order <= 9) ||
                    (this.state.arrayFields[i].fieldName === "last4" && this.showLast4 === true && this.currentPhase - this.state.arrayFields[i].order <= 9 && this.state.arrayFields[i].order - this.currentPhase >= 0)) {

                    this.showLast4 = !this.showLast4;

                    if (i < 3) {

                        if (!this.showLast4)
                            continue;

                        if (this.state.arrayFields[i + 1].order - this.state.arrayFields[i].order < 9)
                            continue;

                    }
                    this.currentPhase = this.currentPhase + 10;
                    throw BreakException;
                }
                if ((this.state.arrayFields[i].fieldName === "location" && this.showLocation === false && this.currentPhase + 10 - this.state.arrayFields[i].order <= 9) ||
                    (this.state.arrayFields[i].fieldName === "location" && this.showLocation === true && this.currentPhase - this.state.arrayFields[i].order <= 9 && this.state.arrayFields[i].order - this.currentPhase >= 0)) {

                    this.showLocation = !this.showLocation;

                    if (i < 3) {

                        if (!this.showLocation)
                            continue;

                        if (this.state.arrayFields[i + 1].order - this.state.arrayFields[i].order < 9)
                            continue;

                    }
                    this.currentPhase = this.currentPhase + 10;
                    throw BreakException;
                }
                if ((this.state.arrayFields[i].fieldName === "amount" && this.showAmount === false && this.currentPhase + 10 - this.state.arrayFields[i].order <= 9) ||
                    (this.state.arrayFields[i].fieldName === "amount" && this.showAmount === true && this.currentPhase - this.state.arrayFields[i].order <= 9 && this.state.arrayFields[i].order - this.currentPhase >= 0)) {

                    this.showAmount = !this.showAmount;

                    if (i < 3) {

                        if (!this.showAmount)
                            continue;

                        if (this.state.arrayFields[i + 1].order - this.state.arrayFields[i].order < 9)
                            continue;

                    }
                    this.currentPhase = this.currentPhase + 10;
                    throw BreakException;
                }
                if ((this.state.arrayFields[i].fieldName === "date" && this.showDate === false && this.currentPhase + 10 - this.state.arrayFields[i].order <= 9) ||
                    (this.state.arrayFields[i].fieldName === "date" && this.showDate === true && this.currentPhase - this.state.arrayFields[i].order <= 9 && this.state.arrayFields[i].order - this.currentPhase >= 0)) {

                    this.showDate = !this.showDate;

                    if (i < 3) {

                        if (!this.showDate)
                            continue;

                        if (this.state.arrayFields[i + 1].order - this.state.arrayFields[i].order < 9)
                            continue;

                    }
                    this.showBackButton = true;
                    this.currentPhase = this.currentPhase + 10;
                    throw BreakException;
                }
            }

        }
        catch (e) {
            if (e !== BreakException) throw e;
        }
        finally {
            this.renderFields();
            document.body.style.cursor = 'default';

            if (this.showForm) {
                this.nextButton.current.disabled = false;
                if (this.startOverButton.current !== null)
                    this.startOverButton.current.disabled = false;
            }
        }

    }

    render() {

        const showModalDialog = this.state.modalShow;
        const showNoResultsDialog = this.state.noResultsModalShow;
        const showForm = this.showForm;
        const showResults = this.showResults;
        const uniqueCardRef = this.uniqueCardRef;
        const showCardSelect = this.showCardSelect;
        
        return (

            <div className="container">

                {showModalDialog
                    ? <Modal show={showModalDialog} deviceType={this.deviceType} closeModal={this.closeModal}>
                        We use cookies to remember this device and make it easier for you to find receipt the next time you visit.
                        <br /><br />
                        Select "yes" if you trust this {this.deviceType} and want us to remember you.
                        <br />
                        Select "no" if you don't.
                        <br /><br />
                        <a href='https://gridserve.com/legal-cookie-policy/' target="_blank">Learn More</a>
                    </Modal>
                    : ''
                }

                {showNoResultsDialog
                    ? <NoResultsModal show={showNoResultsDialog} closeModal={this.closeNoResultsModal} textDataId='noResultsText' btnDataId='noResultsBtn' >
                        {this.noResultsMsg}
                      </NoResultsModal>
                    : ''
                }

                {showCardSelect
                    ? <div className="card" >
                        <div className="form-group">
                            <h3 data-testid="portalHeader">Download Charge Session Receipts</h3>
                        </div>
                        <form onSubmit={this.handleSubmitCardSelect}>
                            <label className="form-label">Your cards: </label>
                            <Select ref={this.cardSelect} 
                                    autoFocus={true }
                                    options={this.cardSelectOptions}
                                    onChange={this.cardSelectOnChange} 
                                styles={this.customStyles}
                                inputId="react-select-input"

                                inputmode='none'
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary50: '#7ac142',
                                            primary25: '#7ac142',
                                            primary: '#7ac142'
                                        },
                                })} >
                                </Select>
                        <br />

                        <br />
                            <button type="submit" data-testid="nextBtn" className="btn btn-primary w-100 mt-auto">Next</button>
                        </form>
                    </div>
                    : ''
                }

                {showForm
                    ? <div className="card" >
                        <div className="form-group">
                            <h3 data-testid="portalHeader">Download Charge Session Receipts</h3>
                        </div>
                        <form onSubmit={this.handleSubmit}>
                            {this.state.fields}

                            <br /><br />
                            <button type='submit' data-testid="nextBtn" ref={this.nextButton} className="btn btn-primary w-100 mt-auto">Next</button>

                        </form>
                       

                        {(this.showBackButton)
                            ? <div><br />< button onClick={this.startOver} ref={this.startOverButton} className="btn btn-secondary w-100 mt-auto">Start over</button></div>
                        : ''
                        }
                    </div>
                    : ''
                }

                {showResults
                    ? <Results CardUniqueRef={uniqueCardRef} backendUrl={this.backendUrl} backButton={this.backButtonFromResults} />
                    : ''
                }

            </div >);

    }
}

export default withCookies(Main);
