import React from 'react';
import NoResultsModal from './NoResultsModal';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';

class Email extends React.Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        this.modalMsg = "";
        this.backendUrl = props.backendUrl;

        this.submitButton = React.createRef();
        this.authButton = React.createRef();
        this.unsubButton = React.createRef();

        this.state = {
            showEmailField: false,
            showAuthCode: false,
            email: '',
            authCode: '',
            showModalDialog: false,
            showSubscribedMessage: false
        };

        this.subscribeButtonOnClick = this.subscribeButtonOnClick.bind(this);
        this.onEmailFormSubmit = this.onEmailFormSubmit.bind(this);
        this.onAuthCodeFormSubmit = this.onAuthCodeFormSubmit.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.unSubscribeButtonOnClick = this.unSubscribeButtonOnClick.bind(this);
    }
    async componentDidMount() {
        window.addEventListener('resize', this.handleKeyboardAvoiding);

        var cardRef = this.props.uniqueCardRef;

        const response = await fetch((this.backendUrl ? this.backendUrl : "") + "/EmailService/IsSubscribed?uniqueCardRef=" + cardRef, {
            method: "GET",
            headers: new Headers({
                RPApiKey: process.env.REACT_APP_API_KEY,
                pragma: 'no-cache',
                'cache-control': 'no-cache'
            })
        });

        const json = await response.json();
        const msg = JSON.parse(json);

        if (msg === true) {
            this.setState({
                showEmailField: false,
                showAuthCode: false,
                showModalDialog: false,
                showSubscribedMessage: true
            });
        }
        else {
            this.setState({
                showEmailField: true,
                showAuthCode: false,
                showModalDialog: false,
                showSubscribedMessage: false
            });
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleKeyboardAvoiding);
    }
    handleKeyboardAvoiding = () => {
        const focusedElement = document.activeElement;
        if (focusedElement.tagName.toLowerCase() === 'input') {

            focusedElement.scrollIntoView({ behavior: "smooth" });


        }
    };

    async unSubscribeButtonOnClick() {

        this.unsubButton.current.innerHTML = "<span class='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>";
        this.unsubButton.current.disabled = true;

        const response = await fetch((this.backendUrl ? this.backendUrl : "") + "/EmailService/Unsubscribe", {
            method: "POST",
            headers: new Headers({
                RPApiKey: process.env.REACT_APP_API_KEY,
                'Content-Type': 'application/json',
                pragma: 'no-cache',
                'cache-control': 'no-cache'
            }),
            body: JSON.stringify({
                UniqueCardRef: this.props.uniqueCardRef
            })
        });

        const json = await response.json();
        const msg = JSON.parse(json);

        if (msg === true) {
            this.modalMsg = "Receiving receipts by email for this card is now cancelled.";
            this.setState({
                showEmailField: true,
                showAuthCode: false,
                showModalDialog: true,
                showSubscribedMessage: false
            });
        }
        else {
            this.modalMsg = "A problem occured while canceling automatic receipt delivery! Please contact support if this problem persists.";
            this.unsubButton.current.innerHTML = "Stop";
            this.unsubButton.current.disabled = false;
            this.setState({
                showEmailField: false,
                showAuthCode: false,
                showModalDialog: true,
            });
        }

    }

    closeModal() {
        this.setState({
            showModalDialog: false
        });
    }

    async onAuthCodeFormSubmit(event) {

        this.authButton.current.innerHTML = "<span class='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>";
        this.authButton.current.disabled = true;

        event.preventDefault();

        const inputCode = event.target.elements.token.value;

        const response = await fetch((this.backendUrl ? this.backendUrl : "") + "/EmailService/CheckAuthCode?uniqueCardRef=" + this.props.uniqueCardRef + "&authCode=" + inputCode, {
            method: "GET",
            headers: new Headers({
                RPApiKey: process.env.REACT_APP_API_KEY,
                pragma: 'no-cache',
                'cache-control': 'no-cache'
            })
        });

        if (response.ok) {

            const finishAuthResponse = await fetch((this.backendUrl ? this.backendUrl : "") + "/EmailService/AuthorizeSubscription" , {
                method: "PATCH",
                headers: new Headers({
                    RPApiKey: process.env.REACT_APP_API_KEY,
                    'Content-Type': 'application/json',
                    pragma: 'no-cache',
                    'cache-control': 'no-cache'
                }),
                body: JSON.stringify({
                    UniqueCardRef: this.props.uniqueCardRef,
                    AuthCode: parseInt(inputCode)
                })
            });

            if (finishAuthResponse.ok) {

                this.modalMsg = "Registration complete. You're all set!";
                this.setState({
                    showEmailField: false,
                    showAuthCode: false,
                    showModalDialog: true,
                    showSubscribedMessage: true
                });

            }
            else {
                this.modalMsg = "A problem occured while setting up automatic receipt delivery! Please contact support if this problem persists.";
                this.setState({
                    showModalDialog: true
                });

                this.authButton.current.innerHTML = "<b>Authorise</b>";
                this.authButton.current.disabled = false;
            }
        }
        else {
            this.modalMsg = "Incorrect authorisation code.";
            this.setState({
                showModalDialog: true
            });

            this.authButton.current.innerHTML = "<b>Authorise</b>";
            this.authButton.current.disabled = false;
        }

    }
    async onEmailFormSubmit(event) {

        this.submitButton.current.innerHTML = "<span class='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>";
        this.submitButton.current.disabled = true;
        event.preventDefault();

        const response = await fetch((this.backendUrl ? this.backendUrl : "") + "/EmailService/SendAuthorizationEmail"  , {
            method: "POST",
            headers: new Headers({
                RPApiKey: process.env.REACT_APP_API_KEY,
                'Content-Type': 'application/json',
                pragma: 'no-cache',
                'cache-control': 'no-cache'
            }),
            body: JSON.stringify({
                Value: event.target.elements.email.value,
                UniqueCardRef: this.props.uniqueCardRef
            })
        });

        if (response.ok) {
            this.setState({
                showEmailField: false,
                showAuthCode: true,
                email: event.target.elements.email.value,
            })
        }
    }

    subscribeButtonOnClick() {
        this.setState({
            showEmailField: true,
            showAuthCode: false
        })
    }

    render() {

        const showModal = this.state.showModalDialog;
        const showMessage = this.state.showSubscribedMessage;
        const showEmailInput = this.state.showEmailField;
        const showAuthCodeInput = this.state.showAuthCode;

        if (showModal === false && showMessage === false && showEmailInput === false && showAuthCodeInput === false)
            return ( <>
                        <br /> <br /> 
                    </>)

        return (<> 
                {showMessage
                ? <div className="d-flex justify-content-center pe-2">
                    <p className="ps-2 pt-2 pe-5 lead" >  <b> This card has been registered to receive receipts by email. Click to unsubscribe.</b> </p>
                    <button data-testid="stopBtn" onClick={this.unSubscribeButtonOnClick} ref={this.unsubButton} className="btn btn-secondary w-25  align-self-center">Stop</button>
                    </div>
                    : ''
                }
                {showEmailInput
                ? <form onSubmit={this.onEmailFormSubmit} > 
                    <div className="form-group row d-flex justify-content-center p-2">
                        <label className="col-sm col-form-label text-end" htmlFor="email">Enter your email and get a digital receipt every time you charge with this account number:</label>
                        <div className="col-sm pb-2">
                            <input autoFocus={true} type="email" name="email" className="form-control" id="email" placeholder="name@example.com" required />
                        </div>
                        <div className="col-sm">
                            <button data-testid="submitBtn" type="submit" ref={this.submitButton} className="btn w-100 btn-primary"><b>Submit</b></button>
                        </div>
                    </div>
                         </form>
                    :    ''
                }
                {showAuthCodeInput
                ? <form onSubmit={this.onAuthCodeFormSubmit} >
                    <div className="form-group row d-flex justify-content-center p-2">
                        <label className="col-sm col-form-label text-end" htmlFor="token">Authorisation Code:</label>
                        <div className="col-sm pb-2">
                            <input type="text"
                                name="token"
                                autoFocus={true }
                                className="form-control"
                                id="token"
                                maxLength="4"
                                minLength="4"
                                inputMode="numeric"
                                pattern="[0-9]*" title="Expected 4 digits."
                                autoComplete="one-time-code" placeholder="XXXX" required />
                        </div>

                        <div className="col-sm">
                            <button data-testid="authoriseBtn" type="submit" ref={this.authButton} className="btn w-100 btn-primary"><b>Authorise</b></button>
                        </div>
                        <div className="col-sm">
                            <button data-testid="resendBtn" data-bs-toggle="tooltip" data-bs-placement="top" title="Please wait a couple of minutes before resending token." type="button" onClick={this.subscribeButtonOnClick} className="btn btn-outline-secondary btn-sm">Resend</button>
                        </div>
                    </div>

                </form>
                : ''
                } 
                {showModal
                ? <NoResultsModal show={showModal} closeModal={this.closeModal} btnDataId='emailBtn' textDataId='emailText' >
                        {this.modalMsg}
                    </NoResultsModal>
                    : ''
                }
        </>);

    }
}

export default withCookies(Email);
